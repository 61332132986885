import React from 'react';

function HomeSmart() {

  return(
    <>
      <meta httpEquiv='/HomeSmart'/>
    </>
  );
}

export default HomeSmart;
